import { createElement as rc, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { ErrorBoundary, hooks, Modal, Popper, testProperties } from 'lib_ui-primitives';
import { constants, dbViews, metadata } from 'lib_ui-services';
import DropDownList from './DropDownList';
import DownshiftProvider, { useDownshift } from './DownshiftProvider';
import ReadProvider from '../../contextProviders/ReadProvider';
import useEventSink from '../../../hooks/useEventSink';
import lodash from 'lodash';
const { get } = lodash;
const { set } = lodash;
const { uniq } = lodash;
import PropTypes from 'prop-types';
import useRegisterWithFilterInterdependencyBoundary from '../../../hooks/useRegisterWithFilterInterdependencyBoundary';
import useAssembleRecords from './useAssembleRecords';
import useInterceptRef from './useInterceptRef';
import logging from '@sstdev/lib_logging';
import { ComboContainer, InputAndButton, ScanLabelAndField } from './styles';
import { useMoveFocusToNext } from '../../contextProviders/FocusProvider';
import DropDownInput from './DropDownInput';
import ComboButton from './ComboButton';
import useReads from '../../../hooks/useReads';
import useIsDisabledByFilter from './useIsDisabledByFilter';

const { getTitleAlternative } = metadata;
const { EMPTY_ARRAY } = dbViews.emptyDbView;
const { useTimeout } = hooks;

// constant to avoid unnecessary rerenders
const emptyArray = [];
const EMPTY_HNODE = {};
const _p = {
    useAssembleRecords,
    useReads,
    selectItemTimeout: 1000,
    onStateChange,
    createPopper: Popper.createPopper,
    useMinimalItem: useMinimalEntry,
    hasOnlyOneRecord,
    useIsDisabledByFilter
};
export const _private = _p;
DropDown.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    active: PropTypes.bool.isRequired
};
/**
 * There's a lot going on in here.
 * Some high level info:
 * Most of the logic for when the dropdown menu/list of items will show (and similar
 * timing and action handling logic) is handled by the downshift.js library.
 * Downshift will also try to add accessibility information.  Downshift expects us to
 * provide the actual UI elements that will receive the actions, updates, etc.
 * The popper.js library is for non-mobile only, and will handle laying out where the menu
 * of items is on the screen.  It tries to keep the menu on the screen, moving it around
 * as necessary (within reason).
 * Because of limited real-estate, the mobile view will display a modal for the menu of
 * items, trying to fill the whole screen.
 */
function DropDown(props) {
    // Some consumers of this abstract component use namespace/relation instead of
    // foreignNamespace/foreignRelation.
    const foreignNamespace = props?.hNode?.foreignNamespace || props?.hNode?.namespace;
    const foreignRelation = props?.hNode?.foreignRelation || props?.hNode?.relation;
    //this value will appear in the input itself (not the option list)  when empty
    const emptyValue = useMinimalEntry(props?.hNode, '');
    const {
        value: selectedItem = emptyValue,
        setValue,
        errors,
        hNode,
        autoFocus = false,
        active,
        selectedValues = emptyArray,
        hNode: {
            id,
            title,
            propertyName = getTitleAlternative(foreignNamespace, foreignRelation),
            inputDisplayProperties = emptyArray,
            dropdownDisplayProperties = emptyArray
        }
    } = props || { hNode: EMPTY_HNODE };

    // Some predefined (non-selectable) list entries
    const unassignedValue = useMinimalEntry(hNode, 'Unassigned');
    const loadingValue = useMinimalEntry(hNode, 'Loading...');
    //this value will appear in the option list when there are no records
    const emptyListValue = useMinimalEntry(props?.hNode, props?.hNode?.emptyHint || '');

    const moveFocusToNext = useMoveFocusToNext();

    // The metadata defines how the input text should be formatted
    const inputItemToString = useCallback(
        item => getItemValuesText(item, propertyName, inputDisplayProperties),
        [propertyName, inputDisplayProperties]
    );

    // The metadata defines how the list entry text should be formatted
    const listItemToString = useCallback(
        item => getItemValuesText(item, propertyName, dropdownDisplayProperties),
        [propertyName, dropdownDisplayProperties]
    );

    const { native } = useTheme();

    const [inputValue, setInputValue] = useState(inputItemToString(selectedItem));
    const [subscribe] = useEventSink();

    // Register so that we can get clearSelection messages when appropriate
    // (i.e. when a parent dropdown selection changes making this
    // selection invalid).
    useRegisterWithFilterInterdependencyBoundary(hNode);
    /**
     * If a clear selection event occurs for this foreignNamespace/foreignRelation, then
     * set the dropdown value to empty.
     * This is probably because a parent dropdown has cleared its selection (i.e. Company > Building)
     */
    useEffect(() => {
        if (foreignNamespace && foreignRelation) {
            return subscribe({ verb: 'clearSelection', namespace: foreignNamespace, relation: foreignRelation }, () => {
                setValue(emptyValue);
            });
        }
    }, [subscribe, foreignNamespace, foreignRelation, setValue, emptyValue]);

    // Handles when a dropdown list entry is selected
    const onChange = useCallback(
        selectedItem => {
            if ([unassignedValue, loadingValue, emptyListValue].includes(selectedItem)) {
                setValue(undefined);
            } else {
                setValue(selectedItem);
                setInputValue(inputItemToString(selectedItem));
            }
        },
        [inputItemToString, setValue, unassignedValue, loadingValue, emptyListValue]
    );

    // Handles when Downshift tells us that the dropdown is opening or closing
    const onStateChange = useCallback(
        (changes, { inputValue, selectedItem }) => {
            logging.debug('[DROPDOWN] onStateChange changes:', changes);
            _p.onStateChange(
                changes,
                inputValue,
                inputItemToString,
                selectedItem,
                setInputValue,
                native,
                moveFocusToNext,
                active
            );
        },
        [inputItemToString, moveFocusToNext, active, native]
    );
    // desiredSensorTypes is needed by ReadProvider to capture reads for this
    // component
    const { scanRfid = false, scanBarcode = false } = hNode;
    const desiredSensorTypes = useMemo(() => {
        const _sensorTypes = [constants.sensorTypes.MANUAL];
        if (scanRfid) {
            _sensorTypes.push(constants.sensorTypes.RFID);
        }
        if (scanBarcode) {
            _sensorTypes.push(constants.sensorTypes.BARCODE);
        }
        return _sensorTypes;
    }, [scanRfid, scanBarcode]);

    // Downshift needs a reference to the root component of the dropdown
    // pass this refKey value down into both components so they can coordinate
    // the placement of it and giving it to downshift.
    //const refKey = useRef();
    // prettier-ignore
    return rc(ErrorBoundary, null,
        // Capture desired scan types for this component
        rc(ReadProvider, { id: `${title}${id}`, desiredSensorTypes, hNode, active },
            rc(DownshiftProvider, {
                selectedItem,
                inputItemToString,
                onStateChange,
                inputValue,
                onChange,
                id
            },
                rc(InnerDropDown, {
                    ...props,
                    inputItemToString,
                    listItemToString,
                    setInputValue,
                    unassignedValue,
                    emptyListValue,
                    loadingValue,
                    emptyValue,
                    foreignNamespace,
                    foreignRelation,
                    propertyName,
                    onChange,
                    errors,
                    autoFocus,
                    desiredSensorTypes,
                    selectedValues
                })
            )
        )
    );
}
DropDown.displayName = 'AbstractDropDown';
const _DropDown = memo(DropDown);

export default _DropDown;

/**
 * Unless you think of something I didn't, this should only contain code affecting the props
 * provided by Downshift (e.g. input props) and the actual component rendering.
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
const InnerDropDown = props => {
    const { mobile, native, viewMargin } = useTheme();
    const {
        // prop getters
        getRootProps,
        getMenuProps,
        getItemProps,
        getInputProps,
        getToggleButtonProps,
        getLabelProps,
        // actions
        closeMenu,
        openMenu,
        setHighlightedIndex,
        // state
        highlightedIndex,
        isOpen,
        inputValue,
        selectItem,
        selectedItem,
        addInputChangeHandler
    } = useDownshift();

    // Some consumers of this abstract component use namespace/relation instead of
    // foreignNamespace/foreignRelation.
    const foreignNamespace = props.hNode.foreignNamespace || props.hNode.namespace;
    const foreignRelation = props.hNode.foreignRelation || props.hNode.relation;
    const {
        // From Dropdown (above)
        inputItemToString,
        listItemToString,
        setInputValue,
        unassignedValue,
        emptyListValue,
        loadingValue,
        emptyValue,
        selectedValues,
        // from original props
        value = emptyValue,
        disabled: _disabled,
        className,
        style,
        hNode,
        propertyName,
        hNode: {
            id,
            displayUnassignedRow = true,
            alwaysDisplayUnassignedRow = false,
            autoHide: _autoHide = false,
            treePosition: { sequence }
        },
        records: fixedRecords,
        errors,
        autoFocus,
        onFocus: _onFocus,
        onBlur: _onBlur,
        supportWildCard = false
    } = props || {};
    const autoHide = asBoolean(_autoHide);
    const rootProps = getRootProps();
    const rootRef = useInterceptRef(rootProps);
    const menuProps = getMenuProps({}, { suppressRefError: true });
    const menuRef = useInterceptRef(menuProps);
    const [inputIsDirty, setInputIsDirty] = useState(false);
    const [autoHideTriggered, setAutoHideTriggered] = useState(false);
    const delayedSetInputIsDirty = useTimeout(setInputIsDirty);
    const { mostRecentIndividualRead, reads, reset } = _p.useReads();
    const moveFocusToNext = useMoveFocusToNext();
    const allowStateChange = useRef(true);
    const disabledByFilter = _p.useIsDisabledByFilter(hNode);
    const disabled = _disabled || disabledByFilter;

    // If a user event occurs that implies the user would prefer to select an item
    // that matches the dropdown input (e.g. barcode scan or enter button typed),
    // then set this to true. This will cause a useEffect to select the item if
    // there is only one item that matches the input.
    const [selectItemIfPossible, setSelectItemIfPossible] = useState(false);

    // Prevent async ops from setting state if the component is unmounted
    useEffect(
        () => () => {
            allowStateChange.current = false;
        },
        []
    );

    const onFocus = useCallback(
        e => {
            _onFocus?.(e);
        },
        [_onFocus]
    );

    // These ref temp values are to prevent the onBlur from being recreated when these
    // values change, but still give access to the latest values inside the onBlur callback
    // (via a the useRef.current reference).
    // This is necessary because recreating onBlur sets off a chain of events:
    // onModalChange is recreated which in turn causes the modal.native.js
    // to call onChange when nothing has really changed.
    const tempValue = useRef();
    tempValue.current = value;
    const tempInputIsDirty = useRef();
    tempInputIsDirty.current = inputIsDirty;
    const onBlur = useCallback(
        e => {
            if (native) {
                reset();
                _onBlur?.(e);
                return;
            }
            // Get the menu element ref (even if menuRef is function ref)
            let menuElement;
            if (typeof menuRef === 'function') {
                menuElement = menuRef();
            } else {
                menuElement = menuRef.current;
            }
            // If the new relatedTarget is not the dropdown menu or inside the rootRef then blur
            if (
                e.relatedTarget == null ||
                (!rootRef.current.contains(e.relatedTarget) && menuElement !== e.relatedTarget)
            ) {
                // When focus leaves the control, if the input value is dirty, revert it to the
                // selected item's text
                if (tempInputIsDirty.current) {
                    setInputValue(tempValue.current?.title ?? '');
                }
                //reset the Read Context. If a selection happened due to it filtering down to 1,
                //that selection is maintained elsewhere
                reset();
                _onBlur?.(e);
            }
        },
        [_onBlur, rootRef, menuRef, setInputValue, reset, native]
    );

    const searchText = inputItemToString(selectedItem) === inputValue ? '' : inputValue;
    let {
        recordCount,
        records: _records,
        viewReady
    } = _p.useAssembleRecords(
        hNode,
        searchText,
        fixedRecords,
        isOpen || autoHide || reads?.get()?.length,
        supportWildCard
    );

    // Avoid several react warnings by putting this outside the render cycle
    // inside a timeout.
    const selectRfidRecordIfPossible = useTimeout(
        selectedItem => {
            setInputValue(inputItemToString(selectedItem));
            selectItem(selectedItem);
            closeMenu();
            //we got what we need. clear the reads to avoid subsequent filtering
            reset();
            moveFocusToNext();
        },
        [setInputValue, selectItem, closeMenu, reset, inputItemToString, moveFocusToNext],
        0
    );

    const [records, setRecords] = useState(EMPTY_ARRAY);
    useEffect(() => {
        //use a post-filter, rather than trying to somehow merge this into useAssembleRecords
        //If and only if we have RFID reads
        if (viewReady && reads?.get().length) {
            //when we filter the records by those reads
            const filteredRecords = _records.filter(record =>
                reads.get().some(r => {
                    if (r.sensorType === 'BARCODE') {
                        return [record[propertyName]].includes(r.asciiTagId);
                    }
                    return [record.tagId, record.locationTagId].includes(r.tagId);
                })
            );
            logging.debug(
                `[DROPDOWN] Filtered ${_records.length} records using ${reads.length} reads, with ${filteredRecords.length} results`
            );
            //if there is only 1 record that matches
            if (filteredRecords.length === 1) {
                //select that record
                const selectedItem = filteredRecords[0];
                selectRfidRecordIfPossible(selectedItem);
                setRecords(filteredRecords);
            } else if (filteredRecords.length) {
                //if multiple records matched, return all matching records
                openMenu();
                setRecords(filteredRecords);
            }
        }
        //if nothing matched, or if there were no reads to begin with, return the original record set.
        setRecords(_records);
    }, [selectRfidRecordIfPossible, viewReady, reads, _records, openMenu, propertyName]);

    const valueToDisplayWhenListEmpty = viewReady ? emptyListValue : loadingValue;

    // This is to prevent the useEffect cleanup (and addInputChangeHandler unsubscribe) below
    // from running when mostRecentIndividualRead changes.
    // Reads were getting lost in between the time when the change handler was unsubscribed
    // and resubscribed.
    const tempMostRecentIndividualRead = useRef();
    tempMostRecentIndividualRead.current = mostRecentIndividualRead;
    // This is included here because it changes isDirty and isDirty is in here because
    // it is only needed by onBlur and the DropDownList.
    // The content of this hook should probably only be called when mounting the dropdown,
    // but addInputChangeHandler returns an unsubscribe function just in case
    useEffect(() => {
        // Listen for input value changes
        const removeHandler = addInputChangeHandler((inputValue, { selectedItem }) => {
            logging.debug(`[INNERDROPDOWN] Input value changed to ${inputValue}`);
            setInputValue(inputValue);
            // If the input has something in it other than the selectedItem title,
            // then set inputIsDirty to true;
            const isDirty = inputItemToString(selectedItem) !== inputValue;
            if (!isDirty) {
                // Wait a second for view data to catch up (i.e. repopulate with unfiltered data)
                // or the value will be cleared.
                // See "Value Validation" above for code that clears it.
                delayedSetInputIsDirty(false);
            } else {
                setInputIsDirty(true);
            }
        });
        return () => {
            logging.debug('[INNERDROPDOWN] removeHandler called');
            removeHandler();
        };
    }, [addInputChangeHandler, delayedSetInputIsDirty, inputItemToString, setInputValue]);

    // If a user event occurs that implies the user would prefer to select an item
    // that matches the dropdown input (e.g. barcode scan or enter button typed),this
    // will select the item if there is only one item that matches the input.
    useEffect(() => {
        if (
            (selectItemIfPossible || mostRecentIndividualRead?.tagId === inputValue) &&
            records.length === 1 &&
            inputIsDirty
        ) {
            selectItem(records[0]);
            closeMenu();
            reset();
        }
    }, [
        selectItemIfPossible,
        records,
        mostRecentIndividualRead,
        selectItem,
        closeMenu,
        reset,
        inputIsDirty,
        inputValue
    ]);

    const [, , request] = useEventSink();
    useEffect(() => {
        if (autoHide) {
            _p.hasOnlyOneRecord(foreignNamespace, foreignRelation, request).then(onlyOne => {
                if (onlyOne) {
                    setAutoHideTriggered(true);
                    if (!(selectedItem && selectedItem._id === records[0]?._id)) {
                        //#5992 if the currently selected item is already the same as our selection,
                        //don't re select the same record which will trigger a clearSelection event
                        //and will clear dependent drop downs.
                        selectItem(records[0]);
                    }
                }
            });
        }
    }, [records, autoHide, selectItem, selectedItem, request, foreignNamespace, foreignRelation]);

    const inputProps = getInputProps();
    inputProps.inputRef = useRef();
    inputProps.name = `drop-down-input-${hNode.title}`;
    inputProps.onBlur = onBlur;
    // If the modal unmounts, also call onBlur
    const onModalChange = useCallback(
        changeType => {
            if (changeType === 'unmount') {
                onBlur({});
            }
        },
        [onBlur]
    );

    inputProps.onFocus = onFocus;
    inputProps.onKeyDown = useCallback(
        e => {
            if (e.key === 'Enter' && inputIsDirty) {
                setSelectItemIfPossible(true);
            }
        },
        [inputIsDirty]
    );

    useEffect(() => {
        if (isOpen) {
            inputProps.inputRef.current?.focus();
        }
    }, [inputProps.inputRef, isOpen]);

    // Mounts the dropdown list for non-mobile UIs (uses Popper.js)
    const inputAndButtonRef = useRef();
    useEffect(() => {
        let instance;
        if (isOpen && !mobile) {
            // Because of the !mobile flag, this should not be hit in react-native.
            // Downshift uses refs to keep track of the pieces of the dropdown and that
            // makes forwarding refs really complicated, so this querySelector
            // simplifies things.
            const menu = document.querySelector(`#bb${id}-menu`);
            instance = _p.createPopper(inputAndButtonRef.current, menu, {
                strategy: 'fixed',
                placement: 'bottom-start',
                modifiers: [
                    { name: 'flip', enabled: true },
                    { name: 'hide', enabled: true },
                    { name: 'offset', options: { offset: [viewMargin, -1 * viewMargin] } }
                ]
            });
        }
        return () => {
            // destroy the popper instance when the dropdown closes.
            if (instance != null) instance.destroy();
        };
    }, [isOpen, mobile, id, viewMargin]);

    // To avoid database lookups, extra stuff to render, etc. only render list if dropdown is open
    const RenderedDropDownList = !isOpen
        ? null
        : rc(DropDownList, {
              displayUnassignedRow,
              alwaysDisplayUnassignedRow,
              getItemProps,
              menuProps,
              highlightedIndex,
              hNode,
              id,
              inputIsDirty,
              isOpen,
              listItemToString,
              records,
              recordCount,
              unassignedValue,
              loadingValue,
              emptyListValue: valueToDisplayWhenListEmpty,
              value,
              closeMenu,
              setHighlightedIndex,
              onFocus,
              onBlur,
              selectedValues
          });

    // If autoHide is enabled and there is only one record in the starting
    // recordset, the hide the dropdown.
    if (autoHideTriggered || (!viewReady && autoHide)) return null;

    // Separate the main portion of the dropdown.  This is to allow it to be
    // rendered in a modal for native devices, and without for desktops.
    // prettier-ignore
    const mainPortion = rc(ComboContainer, { id: `comboContainer-${id}`, isOpen, ...rootProps, 'aria-labelledby': undefined },
        rc(ScanLabelAndField, null,
            rc(InputAndButton, { ref: inputAndButtonRef, isOpen },
                rc(DropDownInput, {
                    disabled,
                    autoFocus: autoFocus || (mobile && isOpen),
                    inputProps,
                    labelProps: getLabelProps(),
                    hNode,
                    className,
                    style,
                    errors,
                    sequence
                }),
                !disabled && rc(ComboButton, {
                    isOpen,
                    mobile,
                    id,
                    onBlur,
                    toggleButtonProps: getToggleButtonProps()
                })
            )
        ),
        RenderedDropDownList
    );

    // Native doesn't **really** do absolute position, clicks are lost and sometimes elements
    // don't render if the control moves outside the rectangle of its parent, so we must put
    // it in a Modal to have it go full screen.
    if (native && isOpen) {
        return rc(
            Modal,
            {
                id: `dropdownModal-${id}`,
                onChange: onModalChange,
                visible: true,
                ...testProperties(hNode, 'modal')
            },
            mainPortion
        );
    }
    return mainPortion;
};
InnerDropDown.displayName = 'InnerDropDown';
InnerDropDown.propTypes = {
    hNode: PropTypes.shape({
        foreignNamespace: PropTypes.string,
        foreignRelation: PropTypes.string,
        namespace: PropTypes.string,
        relation: PropTypes.string,
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    active: PropTypes.bool.isRequired
};

/**
 * This has been extracted to allow it to be tested independently.  It has
 * a lot of arguments because many of these are inside the components scope.
 * @param {object} changes - change object supplied by downshift
 * @param {string} inputValue - current input element value
 * @param {function} inputItemToString - converts an item to the string to display in the input
 * @param {object} selectedItem - the selected item
 * @param {function} setInputValue
 * @param {bool} native - true if running on a native device
 * @param {function} moveFocusToNext - moves the UI focus to the next eligible component
 * @param {bool} active - true if the dropdown has focus
 */
function onStateChange(
    changes,
    inputValue,
    inputItemToString,
    selectedItem,
    setInputValue,
    native,
    moveFocusToNext,
    active
) {
    if (changes.hasOwnProperty('isOpen')) {
        // Drop down closes
        if (!changes.isOpen) {
            if (!changes.isOpen && inputValue !== inputItemToString(selectedItem)) {
                setInputValue(inputItemToString(selectedItem));
            }
            // These 👇could be combined to one `if` statement, but this seems a little easier to mentally
            // parse
            // Native downshift reports an '__autocomplete_unknown__' change type when the user
            // types an Enter key to select a value.
            if (native && ['__autocomplete_unknown__', 0].includes(changes.type)) {
                /* Production downshift uses numbers for these constants 😒 */
                moveFocusToNext();
            } else if (active) {
                // web and input active and user types Enter key.
                moveFocusToNext();
            } else if (
                // User clicks the item in the dropdown menu
                // Production downshift uses numbers for these constants 😒
                ['__autocomplete_click_item__', 9].includes(changes.type) &&
                changes.selectedItem &&
                !changes.selectedItem.isDefaultRecord
            ) {
                moveFocusToNext();
            }
        }
    }
}

/**
 * Extracts the display string from the entry, preferring to extract using the
 * displayProperties, but falling back on the propertyName.
 * @param {Object} entry
 * @param {string} [propertyName] - If there are no displayProperties or there isn't any data on the entry for the displayProperties, this will be used instead.  If there is a title alternative (e.g. assetNo or rmaNo) it should already have been determined and passed in.  This method won't do it for you.
 * @param {string[]} [displayProperties=[]]
 * @returns {string} string
 */
function getItemValuesText(entry, propertyName, displayProperties = []) {
    if (!displayProperties.length) {
        return get(entry, propertyName, '');
    }

    const displayValues = displayProperties.map(dp => get(entry, dp, ''));
    const existingDisplayValues = displayValues.filter(dv => !!dv);

    // This may be legacy data, ensure something displays.
    if (existingDisplayValues.length === 0) {
        return get(entry, propertyName, '');
    }

    return existingDisplayValues.join(', ');
}

function asBoolean(value) {
    if (typeof value === 'boolean') return value;
    else if (typeof value === 'string') return value.toLowerCase() === 'true';
    return !!value;
}

/**
 * This should get an entry containing the minimalText in all fields needed
 * to display the entry properly (in both the input and menu item displays).
 * For use with things like the 'Unassigned' entry or the '' (empty text)
 * entry.
 * @param {object} hNode
 * @param {string} minimalText - the text the user should see when viewing this item
 * @returns an entry to display in the dropdown
 */
function useMinimalEntry(hNode, minimalText) {
    return useMemo(() => {
        const foreignNamespace = hNode?.foreignNamespace || hNode?.namespace;
        const foreignRelation = hNode?.foreignRelation || hNode?.relation;
        // use || here instead of ?? because propertyName might be === '' when `inputDisplayProperties` and `dropdownDisplayProperties` are configured.
        const propertyName = hNode?.propertyName || getTitleAlternative(foreignNamespace, foreignRelation);
        const inputDisplayProperties = hNode?.inputDisplayProperties ?? emptyArray;
        const dropdownDisplayProperties = hNode?.dropdownDisplayProperties ?? emptyArray;
        let minimalFields = [];
        if (inputDisplayProperties.length > 0) {
            minimalFields.push(inputDisplayProperties[0]);
        }
        if (dropdownDisplayProperties.length > 0) {
            minimalFields.push(dropdownDisplayProperties[0]);
        }
        if (minimalFields.length === 0) {
            minimalFields.push(propertyName);
        }
        minimalFields = uniq(minimalFields);
        const entry = { isDefaultRecord: true, _id: '0'.repeat(24) };
        minimalFields.forEach(field => {
            set(entry, field, minimalText);
        });
        return entry;
    }, [hNode, minimalText]);
}

async function hasOnlyOneRecord(namespace, relation, request) {
    const result = await request(
        { criteria: { 'meta.deleted': { $exists: false } } },
        {
            verb: 'count',
            namespace,
            relation
        }
    );
    return result.result[0] === 1;
}
